import axios from "axios";
import { API_URL } from "../config/env";

class AuthService {
  static login = (data) => {
    return axios.post(`${API_URL}/auth/login`, data);
  };

  static change_password = (tokens, data) => {
    return axios.post(`${API_URL}/auth/change-password`, data, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };
}

export default AuthService;
