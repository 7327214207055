import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  tokens: {},
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.user = { ...state.user, ...action.payload.user };
      state.tokens = { ...state.tokens, ...action.payload.tokens };
      state.isLoggedIn = true;
    },
    signOut: (state) => {
      state.user = {};
      state.tokens = {};
      state.isLoggedIn = false;
    },
  },
});

export const { signIn, signOut } = authSlice.actions;
export default authSlice.reducer;
