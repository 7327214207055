import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_verifications: [],
  user_withdraws: [],
  user_deposits: [],
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    setVerifications: (state, action) => {
      state.user_verifications = action.payload;
    },
    setWithdraws: (state, action) => {
      state.user_withdraws = action.payload;
    },
    setDeposits: (state, action) => {
      state.user_deposits = action.payload;
    },
    removeDeposit: (state, action) => {
      state.user_deposits = state.user_deposits.filter(
        (e) => e.deposit_id !== action.payload
      );
    },
    removeWithdraw: (state, action) => {
      state.user_withdraws = state.user_withdraws.filter(
        (e) => e.withdraw_id !== action.payload
      );
    },
    removeKyc: (state, action) => {
      state.user_verifications = state.user_verifications.filter(
        (e) => e.verif_id !== action.payload
      );
    },
  },
});

export const {
  setVerifications,
  setWithdraws,
  setDeposits,
  removeDeposit,
  removeWithdraw,
  removeKyc,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
