import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const NoRequireAuth = ({ children }) => {
  const { isLoggedIn } = useSelector((state) => state.authSlice);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  return children;
};
