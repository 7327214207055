import React, { useEffect, useState } from "react";
import DepositService from "../Services/DepositService";
import { Input } from "../Components/Input";
import { useServerError } from "../Utils/handleServerError";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const UpdateWallet = () => {
  const [mode, setMode] = useState("view");

  const [loading, setLoading] = useState(false);

  const { tokens } = useSelector((state) => state.authSlice);

  const [form, setForm] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  const buttton = {
    marginTop: "2rem",
  };

  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3rem",
    marginBottom: "2rem",
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "3rem",
    marginBottom: "2rem",
  };

  async function getAddresses() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await DepositService.get_addresses(tokens);

      if (res.data.status) {
        setForm({ ...res.data.data });
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    getAddresses();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    if (mode === "view") {
      setMode("edit");

      return;
    }

    if (loading) return;

    setLoading(true);

    try {
      const res = await DepositService.update_addresses(tokens, form);

      if (res.data.status) {
        setMode("view");
        toast(res.data.message, { type: "success", theme: "colored" });
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  return (
    <div style={style}>
      <form className="form" style={style} onSubmit={handleSubmit}>
        <div>
          <div>
            <Input
              text={"BTC Wallet Address"}
              input={"text"}
              read={mode === "view"}
              name={"btc_wallet"}
              require={true}
              value={form.btc_wallet}
              onChange={handleChange}
            />
          </div>

          <div style={{ height: 16 }} />

          <div>
            <Input
              text={"ETH Wallet Address"}
              input={"text"}
              read={mode === "view"}
              name={"eth_wallet"}
              require={true}
              value={form.eth_wallet}
              onChange={handleChange}
            />
          </div>

          {mode === "edit" && (
            <>
              <div style={{ height: 16 }} />

              <Input
                require={true}
                text={"Enter password"}
                input={"password"}
                name={"password"}
                value={form.password}
                onChange={handleChange}
              />
            </>
          )}

          <div style={buttonStyle}>
            <button type="submit" style={buttton} disabled={loading}>
              {mode === "view" ? "Edit" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
