import axios from "axios";
import { API_URL } from "../config/env";

class WithdrawService {
  static get_user_withdraws = (tokens) => {
    return axios.get(`${API_URL}/withdraw`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static change_withdrawal_status = (tokens, data) => {
    return axios.post(`${API_URL}/withdraw/change-status`, data, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };
}

export default WithdrawService;
