import React from "react";
import "../CSS/settings.css";
import { Input } from "../Components/Input";
import { Link, Outlet, useLocation } from "react-router-dom";

export const UserDetails = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="settings-container">
        <Link
          className={`link link-settings ${
            pathname === "/settings/user-settings" ? "link-active" : undefined
          }`}
          to="user-settings"
        >
          Update Settings
        </Link>

        <Link
          className={`link link-settings ${
            pathname === "/settings/update-wallet" ? "link-active" : undefined
          }`}
          to="update-wallet"
        >
          Wallet Address
        </Link>
      </div>
      <Outlet />
    </div>
  );
};
