import React, { useEffect, useState } from "react";
import ProfileService from "../Services/ProfileService";
import { useDispatch, useSelector } from "react-redux";
import { setVerifications, removeKyc } from "../Redux/slices/dashboardSlice";
import { useServerError } from "../Utils/handleServerError";
import "../CSS/kyc.css";
import formatEnum from "../Utils/formatEnum";
import { toast } from "react-toastify";

export const KYC = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { tokens } = useSelector((state) => state.authSlice);
  const { user_verifications } = useSelector((state) => state.dashboardSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  async function getUserVerifications() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await ProfileService.get_user_verifications(tokens);

      if (res.data.status) {
        dispatch(setVerifications(res.data.data));
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  async function changeStatus(data) {
    if (loading) return;

    setLoading(true);

    try {
      const res = await ProfileService.change_status(tokens, data);

      if (res.data.status) {
        dispatch(removeKyc(data.verif_id));

        toast(res.data.message, { theme: "colored", type: "success" });
      }
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  }

  useEffect(() => {
    getUserVerifications();
  }, []);

  return (
    <div className="responsive-table">
      <table>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Date</th>
          <th>Doc Type</th>
          <th>Doc</th>
          <th>Action</th>
        </tr>

        {user_verifications
          .filter((item) => item.status === "Pending")
          .map((item) => (
            <tr key={item.verif_id}>
              <td>{item.verif_user.firstname}</td>

              <td>{item.verif_user.lastname}</td>

              <td>{new Date(item.createdAt).toISOString().split("T")[0]}</td>

              <td>{formatEnum(item.method)}</td>

              <td>
                <a href={item.id_file_url} target="_blank">
                  Check File
                </a>
              </td>

              <td>
                <button
                  className={`kyc_action confirm`}
                  onClick={() =>
                    changeStatus({
                      verif_id: item.verif_id,
                      status: "Accepted",
                    })
                  }
                >
                  Confirm
                </button>

                <button
                  className={`kyc_action decline`}
                  onClick={() =>
                    changeStatus({
                      verif_id: item.verif_id,
                      status: "Rejected",
                    })
                  }
                >
                  Decline
                </button>
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};
