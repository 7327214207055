import React from "react";
import "../CSS/Navbar.css";
import { Input } from "./Input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/AuthService";
import { useDispatch } from "react-redux";
import { signIn } from "../Redux/slices/authSlice";
import { useServerError } from "../Utils/handleServerError";

export const LoginPage = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    handleChange({ target: { name: "loading", value: true } });

    try {
      const res = await AuthService.login(formData);

      if (res.data.status) {
        dispatch(signIn({ user: res.data.data, tokens: res.data.tokens }));
      }
    } catch (e) {
      openErrorMessage(e);
    }

    handleChange({ target: { name: "loading", value: false } });
  }

  return (
    <div>
      <div className="navbar-main_container">
        <div className="navbar-container">
          <div className="login-header">Log In</div>
          <div className="login-details">Please login into your account</div>
          <form onSubmit={handleSubmit}>
            <div className="input-fields">
              <Input
                read={false}
                text={"Email"}
                name={"email"}
                input={"Email"}
                require={true}
                onChange={handleChange}
                value={formData.email}
              />

              <Input
                name={"password"}
                input={"password"}
                require={true}
                text={"Password"}
                onChange={handleChange}
                value={formData.password}
              />
            </div>

            <div className="button-container">
              <button
                onSubmit={handleSubmit}
                type="submit"
                disabled={formData.loading}
              >
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
