import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { signOut } from "../Redux/slices/authSlice";
import { toast } from "react-toastify";

const useServerError = (err) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!err) return;

    if (err.response?.status >= 401 && err.response?.status <= 403) {
      dispatch(signOut());
    }

    const error = err.response?.data || {};

    if (!error || !error.message) {
      if (err.message) {
        error.message = err.message;
      } else {
        error.message = "Something Went Wrong!";
      }
    }

    toast(error.message, { theme: "colored", type: "error" });
  }, [err]);
};

export { useServerError };
