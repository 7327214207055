import axios from "axios";
import { API_URL } from "../config/env";

class ProfitService {
  static generate_profit = (tokens, data) => {
    return axios.post(`${API_URL}/profit`, data, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };
}

export default ProfitService;
