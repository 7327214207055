import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  selectedUser: null,
};

const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    removeUser: (state, action) => {
      state.users = state.users.filter((e) => e.user_id !== action.payload);
    },
    updateUser: (state, action) => {
      state.users = state.users.map((user) => {
        if (user.user_id === action.payload.user_id) {
          return { ...user, ...action.payload };
        }

        return user;
      });
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload || null;
    },
  },
});

export const { setUsers, removeUser, updateUser, setSelectedUser } =
  usersSlice.actions;

export default usersSlice.reducer;
