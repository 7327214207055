import React, { useEffect, useState } from "react";
import "../CSS/user.css";
import UserService from "../Services/UserService";
import { useServerError } from "../Utils/handleServerError";
import { useDispatch, useSelector } from "react-redux";
import {
  removeUser,
  setSelectedUser,
  setUsers,
  updateUser,
} from "../Redux/slices/usersSlice";
import { toast } from "react-toastify";
import PopModal from "../Components/ModelLayout/ModalLayout";
import ConfirmDeleteUser from "../Components/ConfirmDeleteUser";
import UpdateBalanceModal from "../Components/UpdateBalance";
import { useNavigate } from "react-router-dom";
import ConfirmEndPlan from "../Components/ConfirmEndPlan";

export const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.usersSlice);

  const [loading, setLoading] = useState(false);

  const { tokens } = useSelector((state) => state.authSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 1000);
  }

  async function getUsers() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await UserService.get_users(tokens);

      if (res.data.status) {
        dispatch(setUsers(res.data.data));
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const getTotalDeposit = (deposits) => {
    if (!(deposits instanceof Array)) {
      return 0;
    }

    let total = 0;

    deposits.forEach((e) => (total += e.amount));

    return total;
  };

  const [deleteProps, setDeleteProps] = useState(null);

  const openDeleteModal = (handleOk, handleCancel) => {
    setDeleteProps({ handleOk, handleCancel });
  };

  const closeDeleteModal = () => {
    setDeleteProps(null);
  };

  const confirmDelete = () =>
    new Promise((res, _) => {
      openDeleteModal(
        () => res(true),
        () => {
          res(false);
          closeDeleteModal();
        }
      );
    });

  const handleDelete = async (id) => {
    try {
      const proceed = await confirmDelete();

      if (proceed && tokens) {
        setLoading(true);

        const res = await UserService.remove_user(tokens, id);

        if (res.data.status) {
          dispatch(removeUser(id));
          toast(res.data.message, {
            type: "success",
            theme: "colored",
          });
          closeDeleteModal();
        }
      }
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  };

  const [confirmEndProps, setConfirmEndProps] = useState(null);

  const openConfirmEndModal = (handleOk, handleCancel) => {
    setConfirmEndProps({ handleOk, handleCancel });
  };

  const closeConfirmEndModal = () => {
    setConfirmEndProps(null);
  };

  const confirmEnd = () =>
    new Promise((res, _) => {
      openConfirmEndModal(
        () => res(true),
        () => {
          res(false);
          closeConfirmEndModal();
        }
      );
    });

  const endCurrentTradePlan = async (id) => {
    setLoading(true);
    try {
      const proceed = await confirmEnd();

      if (proceed && tokens) {
        setLoading(true);

        const res = await UserService.terminate_active_plan(tokens, id);

        if (res.data.status) {
          dispatch(
            updateUser({
              user_id: id,
              trading_plan: "None",
              plan_upgrade: null,
            })
          );
          toast(res.data.message, {
            type: "success",
            theme: "colored",
          });
          closeConfirmEndModal();
        }
      }
    } catch (error) {
      openErrorMessage(error);
    }
    setLoading(false);
  };

  const [updateProps, setUpdateProps] = useState(null);

  const openUpdateModal = (title, handleSubmit, initialAmount, onClose) => {
    setUpdateProps({ title, initialAmount, handleSubmit, onClose });
  };

  const closeUpdateModal = () => {
    setUpdateProps(null);
  };

  const getNewAmount = (title, initialAmount) =>
    new Promise((res) => {
      openUpdateModal(title, res, initialAmount, () => {
        closeUpdateModal();
        res(null);
      });
    });

  const handleUpdate = async (key, amount, user_id) => {
    try {
      let title = "Update";

      title +=
        key === "balance"
          ? "Balance"
          : key === "demo_balance"
          ? "Demo Balance"
          : "";

      const newAmount = await getNewAmount(title, amount);

      console.log(newAmount, "newAmount");

      if (typeof newAmount !== "number" || !tokens) return;

      setLoading(true);

      const res = await UserService.change_balance(tokens, {
        user_id,
        key,
        amount: newAmount,
      });

      if (res.data.status) {
        toast(res.data.message, { type: "success", theme: "colored" });
        dispatch(updateUser(res.data.data));
      }
    } catch (error) {
    } finally {
      setLoading(false);
      closeUpdateModal();
    }
  };

  const goToWithdrawalLog = (id) => () => {
    dispatch(setSelectedUser(id));
    navigate("/users/withdraw-log");
  };

  const goToDepositLog = (id) => () => {
    dispatch(setSelectedUser(id));
    navigate("/users/deposit-log");
  };

  const goToProfitLog = (id) => () => {
    dispatch(setSelectedUser(id));
    navigate("/users/profit-log");
  };

  const changePlanRequestStatus = async (action, user_id) => {
    setLoading(true);
    try {
      const res = await UserService.change_plan_request_status(tokens, {
        action,
        user_id,
      });

      if (res.data.data) {
        toast(res.data.message, { type: "success", theme: "colored" });
        if (action === "accept") {
          dispatch(
            updateUser({
              user_id,
              trading_plan: res.data.data.newPlan,
              plan_upgrade: null,
            })
          );
        } else {
          dispatch(updateUser({ user_id, plan_upgrade: null }));
        }
      }
    } catch (error) {
      openErrorMessage(error);
    }
    setLoading(false);
  };

  return (
    <>
      {deleteProps && (
        <PopModal modalToggler={deleteProps !== null} onClose={() => {}}>
          <ConfirmDeleteUser
            handleOk={deleteProps.handleOk}
            handleCancel={deleteProps.handleCancel}
          />
        </PopModal>
      )}

      {confirmEndProps && (
        <PopModal modalToggler={confirmEndProps !== null} onClose={() => {}}>
          <ConfirmEndPlan
            handleOk={confirmEndProps.handleOk}
            handleCancel={confirmEndProps.handleCancel}
          />
        </PopModal>
      )}

      {updateProps && (
        <PopModal modalToggler={updateProps !== null} onClose={() => {}}>
          <UpdateBalanceModal
            handleSubmit={updateProps.handleSubmit}
            initialAmount={updateProps.initialAmount}
            title={updateProps.title}
            onClose={updateProps.onClose}
            loading={loading}
          />
        </PopModal>
      )}

      <div className="main_routes">
        <div className="responsive-table">
          <table>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone number</th>
              <th>Country</th>
              <th>State</th>
              <th>City</th>
              <th>Address</th>
              <th>Total Deposit</th>
              <th>Balance</th>
              <th>Demo Balance</th>
              <th>ID Document</th>
              <th>Active Plan</th>
              <th>Plan Upgrade</th>
              <th>Withdrawal History</th>
              <th>Deposit History</th>
              <th>Profit History</th>
              <th>Action</th>
            </tr>

            {users.map((item) => (
              <tr key={item.user_id}>
                <td>{item.firstname}</td>

                <td>{item.lastname}</td>

                <td>{item.email}</td>

                <td>{item.phone || "-"}</td>

                <td>{item.country || "-"}</td>

                <td>{item.state || "-"}</td>

                <td>{item.city || "-"}</td>

                <td>{item.address || "-"}</td>

                <td>{getTotalDeposit(item.deposits)}</td>

                <td>
                  <div>
                    <span>{item.balance}</span>

                    <button
                      className="kyc_action"
                      onClick={() =>
                        handleUpdate("balance", item.balance, item.user_id)
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{item.demo_balance}</span>

                    <button
                      className="kyc_action"
                      onClick={() =>
                        handleUpdate(
                          "demo_balance",
                          item.demo_balance,
                          item.user_id
                        )
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>

                <td>
                  {(item.user_verification &&
                    item.user_verification.id_file_url && (
                      <a
                        target="_blank"
                        href={item.user_verification.id_file_url}
                      >
                        Check File
                      </a>
                    )) ||
                    "-"}
                </td>

                <td>
                  <div>
                    <span>{item.trading_plan || "None"}</span>

                    <button
                      className="kyc_action decline"
                      disabled={
                        !item.trading_plan ||
                        item.trading_plan === "None" ||
                        loading
                      }
                      onClick={() => endCurrentTradePlan(item.user_id)}
                    >
                      End
                    </button>
                  </div>
                </td>

                <td>
                  {item.plan_upgrade ? (
                    <div>
                      <span>{item.plan_upgrade.plan}</span>

                      <div className="upgrade_actions">
                        <button
                          className="kyc_action"
                          disabled={loading}
                          onClick={() =>
                            changePlanRequestStatus("accept", item.user_id)
                          }
                        >
                          Accept
                        </button>
                        <button
                          className="kyc_action decline"
                          disabled={loading}
                          onClick={() =>
                            changePlanRequestStatus("decline", item.user_id)
                          }
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </td>

                <td>
                  <a href="#" onClick={goToWithdrawalLog(item.user_id)}>
                    Open
                  </a>
                </td>

                <td>
                  <a href="#" onClick={goToDepositLog(item.user_id)}>
                    Open
                  </a>
                </td>

                <td>
                  <a href="#" onClick={goToProfitLog(item.user_id)}>
                    Open
                  </a>
                </td>

                <td>
                  <button
                    className={`kyc_action decline`}
                    disabled={loading}
                    onClick={() => handleDelete(item.user_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};
