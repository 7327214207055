import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useServerError } from "../Utils/handleServerError";
import DepositService from "../Services/DepositService";
import { setDeposits, removeDeposit } from "../Redux/slices/dashboardSlice";
import { toast } from "react-toastify";

export const UpdateBalance = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { tokens } = useSelector((state) => state.authSlice);
  const { user_deposits } = useSelector((state) => state.dashboardSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  async function getUserDeposits() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await DepositService.get_user_deposits(tokens);

      if (res.data.status) {
        dispatch(setDeposits(res.data.data));
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  async function changeStatus(data) {
    if (loading) return;

    setLoading(true);

    try {
      const res = await DepositService.change_deposit_status(tokens, data);

      if (res.data.status) {
        dispatch(removeDeposit(data.deposit_id));

        toast(res.data.message, { theme: "colored", type: "success" });
      }
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  }

  useEffect(() => {
    getUserDeposits();
  }, []);

  return (
    <div className="responsive-table">
      <table>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Date</th>
          <th>Amount</th>
          <th>Receipt</th>
          <th>Action</th>
        </tr>

        {user_deposits
          .filter((item) => item.status === "Pending")
          .map((item) => (
            <tr key={item.withdraw_id}>
              <td>{item.user.firstname}</td>

              <td>{item.user.lastname}</td>

              <td>{new Date(item.createdAt).toISOString().split("T")[0]}</td>

              <td>{item.amount}</td>

              <td>
                <a href={item.file_url} target="_blank">
                  Check File
                </a>
              </td>

              <td>
                <button
                  className={`kyc_action confirm`}
                  disabled={loading}
                  onClick={() =>
                    changeStatus({
                      deposit_id: item.deposit_id,
                      status: "Complete",
                    })
                  }
                >
                  Confirm
                </button>

                <button
                  className={`kyc_action decline`}
                  disabled={loading}
                  onClick={() =>
                    changeStatus({
                      deposit_id: item.deposit_id,
                      status: "Declined",
                    })
                  }
                >
                  Decline
                </button>
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};
