import React, { useRef } from "react";
import "../CSS/sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import { IoExitOutline } from "react-icons/io5";
import { IoPersonCircleOutline, IoCloseOutline } from "react-icons/io5";
import "animate.css";
import { IoSpeedometerOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { signOut } from "../Redux/slices/authSlice";
import { CSSTransition } from "react-transition-group";

export const SideBar = (props) => {
  const dispatch = useDispatch();

  const logout = () => {
    props.onClose();
    dispatch(signOut());
  };

  const sideBarRef = useRef(null);

  const { pathname } = useLocation();

  return (
    <CSSTransition
      in={props.isOpen}
      classNames={"container-side"}
      unmountOnExit
      timeout={500}
      nodeRef={sideBarRef}
    >
      <div
        ref={sideBarRef}
        className={`container-side`}
        onClick={props.onClose}
      >
        <div
          className="side-bar-container"
          onClick={(e) => e.stopPropagation()}
        >
          <button className="close" onClick={props.onClose}>
            <IoCloseOutline />
          </button>

          <div>
            <NavLink
              onClick={props.onClose}
              className={`nav-link ${
                pathname.startsWith("/dashboard") ? "active" : undefined
              }`}
              to="/dashboard"
            >
              <IoSpeedometerOutline className={`icon`} />
              Dashboard
            </NavLink>
          </div>

          <div>
            <NavLink
              onClick={props.onClose}
              className={`nav-link ${
                pathname.startsWith("/users") ? "active" : undefined
              }`}
              to="/users"
            >
              <IoPersonCircleOutline className="icon" />
              Users
            </NavLink>
          </div>

          <div>
            <NavLink
              onClick={props.onClose}
              className={`nav-link ${
                pathname.startsWith("/settings") ? "active" : undefined
              }`}
              to="settings"
            >
              <IoSettingsOutline className="icon" />
              Settings
            </NavLink>
          </div>

          <div className="logout-container">
            <span className={"nav-link"} onClick={logout}>
              <IoExitOutline className="icon" />
              Logout
            </span>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
