import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "../CSS/withdrawal-log.css";
import { useServerError } from "../Utils/handleServerError";
import UserService from "../Services/UserService";

const UserWithdrawalLog = () => {
  const { users, selectedUser } = useSelector((state) => state.usersSlice);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { tokens } = useSelector((state) => state.authSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  const selectedUserData = useMemo(() => {
    return users.filter((user) => user.user_id === selectedUser)[0] || null;
  }, [selectedUser, users]);

  const fetchUserWithdraws = async (user_id) => {
    try {
      setLoading(true);
      const res = await UserService.get_user_withdrawal_log(tokens, user_id);

      setData(res.data.data);
    } catch (error) {
      openErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedUser && tokens) {
      fetchUserWithdraws(selectedUser);
    }
  }, [selectedUser, tokens]);

  if (!selectedUser) return null;

  return (
    <div className="main_routes bv-user-withdrawal-log">
      <h4>
        Withdrawal Log For {selectedUserData.firstname}{" "}
        {selectedUserData.lastname}
      </h4>

      {loading && <div className="loading">Loading...</div>}

      {!loading && selectedUser && (
        <div className="responsive-table">
          <table>
            <tr>
              <th>S/N</th>
              <th>Transaction ID</th>
              <th>Wallet</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>

            {data.map((item, idx) => (
              <tr key={item.withdraw_id}>
                <td>{idx + 1}</td>
                <td>{item.withdraw_id}</td>
                <td>{item.wallet}</td>
                <td>{item.amount}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default UserWithdrawalLog;
