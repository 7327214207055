import React from "react";

export const Input = (props) => {
  const style = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  };

  return (
    <div>
      <div className="input" style={style}>
        <label for={`${props.input}`} className="label">
          {props.text}
        </label>
        <input
          required={props.require || false}
          readOnly={props.read || false}
          name={props.name}
          type={props.input}
          onChange={props.onChange}
          placeholder={props.text}
          value={props.value || ""}
        />
      </div>
    </div>
  );
};
