import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirect = ({ path }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (path) {
      navigate(path);
    }
  }, [path]);

  return null;
};

export default Redirect;
