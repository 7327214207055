import axios from "axios";
import { API_URL } from "../config/env";

class ProfileService {
  static get_user_verifications = (tokens) => {
    return axios.get(`${API_URL}/profile/user-verification`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static change_status = (tokens, data) => {
    return axios.post(`${API_URL}/profile/change-status`, data, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };
}

export default ProfileService;
