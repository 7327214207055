import React, { useState } from "react";
import { Input } from "../Components/Input";
import { useServerError } from "../Utils/handleServerError";
import AuthService from "../Services/AuthService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const UserSettings = () => {
  const Style = {
    marginTop: "5rem",
    display: "flex",
    flexDirectiion: "column",
    gap: "2rem",
  };
  const button = {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const container = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const { tokens } = useSelector((state) => state.authSlice);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  const [form, setForm] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubnit = async (e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);

    try {
      const res = await AuthService.change_password(tokens, form);

      if (res.data.status) {
        toast(res.data.message, { theme: "colored", type: "success" });
        setForm({});
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="main-settings_container" style={container}>
        <form
          style={Style}
          className="setting-container"
          onSubmit={handleSubnit}
        >
          <div>
            <Input
              text={"Current Password"}
              input={"password"}
              name="current"
              value={form.current}
              onChange={handleChange}
              require={true}
            />
          </div>

          <div>
            <Input
              text={"New Password"}
              input={"password"}
              name="password"
              value={form.password}
              onChange={handleChange}
              require={true}
            />
          </div>

          <div>
            <Input
              text={"Confirm Password"}
              input={"password"}
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handleChange}
              require={true}
            />
          </div>
          <div style={button}>
            <button type="submit" disabled={loading}>
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
