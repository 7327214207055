import "../CSS/confirm-delete.css";

const ConfirmEndPlan = ({ handleOk, handleCancel, loading }) => {
  return (
    <div className="bv-confirm-delete-user">
      <header>
        <h4>Confirm End</h4>
      </header>

      <div className="body">
        <p>
          Do you want to proceed with the termination of this user's active
          plan?
        </p>
      </div>

      <div className="actions">
        <button onClick={handleOk}>{loading ? "..." : "Continue"}</button>

        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ConfirmEndPlan;
