import { useState } from "react";
import "../CSS/create-profit.css";
import { useServerError } from "../Utils/handleServerError";
import ProfitService from "../Services/ProfitService";
import { useSelector } from "react-redux";

const CreateProfit = ({ handleOk, handleCancel, user_id }) => {
  const [form, setForm] = useState({});

  const { tokens } = useSelector((state) => state.authSlice);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  useServerError(error);

  const openErrorMessage = (error) => {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  };

  const onChange = (key, val) => setForm((prev) => ({ ...prev, [key]: val }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!tokens || !form.amount || !form.profit) {
      return;
    }

    setLoading(true);

    try {
      const res = await ProfitService.generate_profit(tokens, {
        ...form,
        user_id,
      });

      handleOk(res.data.data || null);
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  };

  return (
    <div className="bv-create-profit-modal">
      <header>
        <h4>Generate Profit</h4>
      </header>

      <form id="generateProfitForm" className="body" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="amount" className="form-label">
            Amount
          </label>

          <input
            className="form-control"
            type="number"
            id="amount"
            name="amount"
            required
            value={form.amount || ""}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            min={1}
          />
        </div>

        <div className="form-group">
          <label htmlFor="profit" className="form-label">
            Profit
          </label>

          <input
            className="form-control"
            type="number"
            id="profit"
            required
            name="profit"
            value={form.profit || ""}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            min={1}
          />
        </div>
      </form>

      <div className="actions">
        <button type="submit" disabled={loading} form="generateProfitForm">
          {loading ? "..." : "Save"}
        </button>

        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default CreateProfit;
