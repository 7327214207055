import axios from "axios";
import { API_URL } from "../config/env";

class UserService {
  static get_users = (tokens) => {
    return axios.get(`${API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static change_plan_request_status = (tokens, data) => {
    return axios.post(`${API_URL}/users/change-plan-request-status`, data, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static get_me = (tokens) => {
    return axios.get(`${API_URL}/users/me`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static remove_user = (tokens, id) => {
    return axios.delete(`${API_URL}/users?id=${id}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static terminate_active_plan = (tokens, user_id) => {
    return axios.post(
      `${API_URL}/users/terminate-plan`,
      { user_id },
      {
        headers: {
          Authorization: `Bearer ${tokens.access.token}`,
        },
      }
    );
  };

  static change_balance = (tokens, data) => {
    return axios.post(`${API_URL}/users/change-balance`, data, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static get_user_withdrawal_log = (tokens, user_id) => {
    return axios.get(`${API_URL}/users/withdrawal-log?id=${user_id}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static get_user_deposit_log = (tokens, user_id) => {
    return axios.get(`${API_URL}/users/deposit-log?id=${user_id}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };

  static get_user_profit_log = (tokens, user_id) => {
    return axios.get(`${API_URL}/users/profit-log?id=${user_id}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`,
      },
    });
  };
}

export default UserService;
