import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useServerError } from "../Utils/handleServerError";
import UserService from "../Services/UserService";
import "../CSS/profit-log.css";
import CreateProfit from "../Components/CreateProfit";
import PopModal from "../Components/ModelLayout/ModalLayout";

const UserProfitLog = () => {
  const { users, selectedUser } = useSelector((state) => state.usersSlice);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { tokens } = useSelector((state) => state.authSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 4000);
  }

  const selectedUserData = useMemo(() => {
    return users.filter((user) => user.user_id === selectedUser)[0] || null;
  }, [selectedUser, users]);

  const fetchUserProfits = async (user_id) => {
    try {
      setLoading(true);
      const res = await UserService.get_user_profit_log(tokens, user_id);

      setData(res.data.data);
    } catch (error) {
      openErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedUser && tokens) {
      fetchUserProfits(selectedUser);
    }
  }, [selectedUser, tokens]);

  const [createProfitProps, setCreateProfitProps] = useState(null);

  const openCreateProfitModal = (handleOk, handleCancel) =>
    setCreateProfitProps({ handleOk, handleCancel });

  const closeCreateProfitModal = () => setCreateProfitProps(null);

  const createProfit = () =>
    new Promise((res) => {
      openCreateProfitModal((data) => {
        res(data);
        closeCreateProfitModal();
      }, closeCreateProfitModal);
    });

  const handleAdd = async () => {
    try {
      const profit = await createProfit();

      if (profit) {
        setData((prev) => [profit, ...prev]);
      }
    } catch (error) {}
  };

  if (!selectedUser) return null;

  return (
    <>
      {createProfitProps && (
        <PopModal modalToggler={createProfitProps !== null} onClose={() => {}}>
          <CreateProfit
            handleOk={createProfitProps.handleOk}
            handleCancel={createProfitProps.handleCancel}
            user_id={selectedUser}
          />
        </PopModal>
      )}

      <div className="main_routes bv-user-profit-log">
        <div className="headers">
          <h4>
            Profit Log For {selectedUserData.firstname}{" "}
            {selectedUserData.lastname}
          </h4>

          <button className="btn" onClick={handleAdd}>
            Add
          </button>
        </div>

        {loading && <div className="loading">Loading...</div>}

        {!loading && selectedUser && (
          <div className="responsive-table">
            <table>
              <tr>
                <th>S/N</th>
                <th>Trade ID</th>
                <th>Amount</th>
                <th>Profits</th>
                <th>Earnings</th>
                <th>Date Earned</th>
              </tr>

              {data.map((item, idx) => (
                <tr key={item.profit_id}>
                  <td>{idx + 1}</td>
                  <td>{item.profit_id}</td>
                  <td>{item.amount}</td>
                  <td>{item.profit}</td>
                  <td>{item.earnings}</td>
                  <td>
                    {new Date(item.createdAt).toISOString().split("T")[0]}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfitLog;
