import React from "react";
import "../CSS/nav.css";
import { useState } from "react";
import { IoIosMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { IoPerson } from "react-icons/io5";

export const Navbar = (props) => {
  var navStyle = {
    fontSize: "2rem",
    fontWeight: 500,
    Zindex: "1",
  };
  const [style, setStyle] = useState(navStyle);
  const hidden = {
    display: "none",
  };

  const userStyle = {
    color: "#sffff",
    fontSize: "2rem",
  };

  return (
    <div className="main-nav-container">
      <div className="navbar">
        <div className="side-bar-icon" onClick={props.onClick}>
          <IoIosMenu className="nav-icon" style={style} id={props.id} />
          <IoMdClose style={hidden} id={props.id} />
        </div>
        <h1 className="Admin-bar">
          {" "}
          <NavLink className={"main-link"} to="/">
            Welcome Admin
          </NavLink>
        </h1>
        <div className="Login-icon">
          <IoPerson style={userStyle} />
        </div>

        {/* <NavLink>
          {!Auth.user && (
            <NavLink className={"main-link"} to={"loginpage"}></NavLink>
          )}
        </NavLink> */}
      </div>
    </div>
  );
};
